<script setup>
import DaySelector from '@components/workSchedule/DaySelector.vue';
import { useStore } from 'vuex';
import {
  isToday,
  isWithinInterval,
  parseISO,
  areIntervalsOverlapping,
  isSameDay,
  getHours,
  set,
  addDays,
  subDays,
} from 'date-fns';
import { cloneDeep, get, isEmpty } from 'lodash';
import { ref, computed, watch, onMounted } from 'vue';
import { useRoute } from 'vue-router';

const store = useStore();
const route = useRoute();

const icon = ref({
  morgen: 'sunrise',
  eftermiddag: 'sun',
  aften: 'sunset',
  nat: 'moon-stars',
});

const selectedDay = ref(new Date());
const today = ref(new Date());

onMounted(() => {
  if (!isEmpty(workSchedule.value)) {
    scrollToTimeOfDay();
  }
});

const workSchedule = computed(() => {
  return store.getters['workSchedule/all'];
});

const settings = computed(() => {
  return store.getters['department/settings'];
});

const pinValidation = computed(() => {
  return store.getters['profiles/pinValidation'];
});

const activeOverlay = computed(() => {
  return store.getters['general/activeOverlay'];
});

const institutionSettings = computed(() => {
  return store.getters['institution/settings'];
});

const departmentSettings = computed(() => {
  return store.getters['department/settings'];
});

const departmentId = computed(() => {
  return store.getters['department/id'];
});

const selectedShift = computed(() => {
  return store.getters['workSchedule/selectedShift'];
});

const moduleColor = computed(() => {
  return route.meta.color;
});

const clonedWorkSchedule = computed(() => {
  return cloneDeep(workSchedule.value);
});

const timeSlots = computed(() => {
  const timeSlotsClone = cloneDeep(settings.value.simpleSchedulePlan);

  return timeSlotsClone.sort(
    (a, b) => new Date(a.starttime) - new Date(b.starttime)
  );
});

const shownDays = computed(() => {
  // If the department has checked overwrite institution setting it will be in the shift array.
  const matchingDepartment = institutionSettings.value?.shift?.find(
    (settings) => settings.departmentId == departmentId.value
  );

  return (
    get(
      matchingDepartment,
      'daysForward',
      departmentSettings.value.shift.daysForward
    ) - 1
  ); // Subtract 1 because the core admin setting is not 0 based but 1 should mean 1 days forward.
});

const dateInterval = computed(() => {
  return {
    from: subDays(today.value, shownDays.value),
    to: addDays(today.value, shownDays.value),
  };
});

const editAction = computed(() => {
  return activeOverlay.value.data.action;
});

watch(pinValidation, (pinData) => {
  if (!pinData.validPin || !pinData.pin) return;

  if (editAction.value === 'cancel') {
    store.dispatch('workSchedule/shiftUpdate', {
      shiftId: selectedShift.value.id,
      dates: dateInterval.value,
      cancel: true,
    });
  } else if (editAction.value === 'reactivate') {
    store.dispatch('workSchedule/shiftUpdate', {
      shiftId: selectedShift.value.id,
      dates: dateInterval.value,
      cancel: false,
    });
  }

  if (editAction.value === 'replace') {
    store.dispatch(
      'workSchedule/setReplaceWithEmployeeId',
      activeOverlay.value.data.employee.id
    );

    store.dispatch('workSchedule/shiftUpdate', {
      shiftId: selectedShift.value.id,
      dates: dateInterval.value,
      cancel: false,
      employeeId: activeOverlay.value.data.employee.id,
    });
  }

  if (editAction.value === 'delete') {
    store.dispatch('workSchedule/shiftDelete', {
      shiftId: selectedShift.value.id,
      dates: dateInterval.value,
    });
  }
});

watch(workSchedule, () => {
  if (selectedShift.value.id === undefined) return;
  clonedWorkSchedule.value.find((shift) => {
    if (shift.id === selectedShift.value.id) {
      store.dispatch('workSchedule/setSelectedShift', shift);
      store.dispatch('general/setActiveOverlay', {
        name: 'work-schedule-entry',
        data: { ...shift },
      });
    }
  });
});

function getLabelColors(timeOfDay) {
  return isNowWithinInterval(timeOfDay.starttime, timeOfDay.endtime)
    ? `bg-${moduleColor.value} text-white`
    : 'bg-white';
}

function filteredWorkSchedule(startSetting, endSetting, icon, name) {
  const selectedDayStartSetting = set(selectedDay.value, {
    hours: getHours(parseISO(startSetting)),
  });
  const selectedDayEndSetting = set(selectedDay.value, {
    hours: getHours(parseISO(endSetting)),
  });

  const shiftsForSelectedDay = clonedWorkSchedule.value.filter((schedule) => {
    return (
      isSameDay(new Date(schedule.start), selectedDay.value) ||
      isSameDay(new Date(schedule.end), selectedDay.value)
    );
  });

  const shiftsInTimeOfDay = shiftsForSelectedDay.filter((shift) =>
    areIntervalsOverlapping(
      { start: new Date(shift.start), end: new Date(shift.end) },
      { start: selectedDayStartSetting, end: selectedDayEndSetting }
    )
  );

  return shiftsInTimeOfDay.map((shift) => ({
    ...shift,
    timeOfDayIcon: icon,
    timeOfDayName: name,
  }));
}

function isNowWithinInterval(startSetting, endSetting) {
  return isWithinInterval(today.value, {
    start: parseISO(startSetting),
    end: parseISO(endSetting),
  });
}

function showSingleShift(shift) {
  store.dispatch('workSchedule/setSelectedShift', shift);

  store.dispatch('general/setActiveOverlay', {
    name: 'work-schedule-entry',
    data: shift ? { ...shift } : { ...this.selectedShift },
  });
}

function isShiftToday(shiftEnd) {
  return isToday(parseISO(shiftEnd));
}

function isShiftActive(timeOfDayStart, timeOfDayEnd, shiftStart, shiftEnd) {
  return (
    (isNowWithinInterval(timeOfDayStart, timeOfDayEnd) &&
      isShiftToday(shiftEnd)) ||
    isNowWithinInterval(shiftStart, shiftEnd)
  );
}

function scrollToTimeOfDay() {
  const element = document.querySelector('#scrollHere');
  if (!element) return;

  element.scrollIntoView({ behavior: 'smooth' });
}

function handleSelectedDay(payload) {
  selectedDay.value = payload;
}
</script>

<template>
  <div class="flex flex-col pb-48 h-initial min-h-full">
    <div
      v-for="timeOfDay in timeSlots"
      :id="
        isNowWithinInterval(timeOfDay.starttime, timeOfDay.endtime)
          ? 'scrollHere'
          : ''
      "
      :key="timeOfDay.name"
      class="flex flex-col justify-center items-center bg-charcoal mt-16 mx-16 p-8 rounded-xl bg-opacity-5"
    >
      <div
        class="w-max rounded-lg shadow-lg px-12 py-5 text-3xl font-semibold"
        :class="getLabelColors(timeOfDay)"
      >
        <fa-icon class="pr-1" :icon="['far', `${icon[timeOfDay.icon]}`]" />
        {{ timeOfDay.name }}
      </div>
      <div
        class="flex flex-wrap pt-12 pb-6 space-between justify-center grid-cols-3 flex-grow"
      >
        <div
          v-for="(shift, index) in filteredWorkSchedule(
            timeOfDay.starttime,
            timeOfDay.endtime
          )"
          :key="index"
          class="relative m-2"
        >
          <img
            :src="shift.employee.displayImage"
            alt="Employee"
            class="w-36 h-36 rounded-full border-8 shadow-m border-white object-cover"
            :class="
              isShiftActive(
                timeOfDay.starttime,
                timeOfDay.endtime,
                shift.start,
                shift.end
              ) && !shift.cancelled
                ? ''
                : 'filter grayscale'
            "
            @click="showSingleShift(shift)"
          />
          <div
            v-if="
              (isNowWithinInterval(timeOfDay.starttime, timeOfDay.endtime) &&
                isShiftToday(shift.end) &&
                !shift.cancelled) ||
              isNowWithinInterval(shift.start, shift.end)
            "
            :class="`bg-${moduleColor}`"
            class="absolute right-0 bottom-1 inline-flex rounded-full h-12 w-12 border-4 border-white"
          />
          <div
            v-if="
              isNowWithinInterval(shift.start, shift.end) &&
              isNowWithinInterval(shift.start, shift.end) &&
              shift.cancelled
            "
            class="bg-warning absolute w-12 h-12 rounded-full flex items-center justify-center right-0 bottom-1 border-4 border-white"
          >
            <fa-icon class="text-white text-xl" :icon="['fas', 'times']" />
          </div>
        </div>
      </div>
    </div>
    <daySelector
      class="fixed bottom-80"
      @handle-selected-day="handleSelectedDay"
    />
  </div>
</template>

<style>
.h-initial {
  height: initial !important;
}
</style>
