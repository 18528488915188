<script setup>
import { computed, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { getModuleData } from '@utils/modules';
import { default as ButtonEl } from '@components/shared/Button.vue';
import BaseContentCard from '@components/cards/baseContentCard/BaseContentCard.vue';
import WidgetEmptyCard from '@components/cards/widgetEmptyCard/WidgetEmptyCard.vue';
import BaseContent from '@components/cards/baseContentCard/BaseContent.vue';
import EmployeeGrid from '@components/workSchedule/EmployeeGrid.vue';
import i18n from '@i18n';

const store = useStore();
const router = useRouter();

const props = defineProps({
  moduleName: {
    type: String,
    required: true,
  },
});

const max = ref(-1);

const maxShiftsShown = {
  4: {
    '2x1': 15,
    '1x1': 9,
  },
  9: {
    '2x2': 16,
    '2x1': 8,
    '1x1': 4,
  },
};

const screenConfig = computed(() => {
  return store.getters['department/settings'].screenConfig;
});

const module = computed(() =>
  screenConfig.value.modules.find((module) => module.type === props.moduleName)
);

const { colorHex, icon, emptyKey } = getModuleData()[props.moduleName];

const allShifts = computed(() => {
  if (
    props.moduleName === 'WORK_SCHEDULE' ||
    props.moduleName === 'SIMPLE_WORK_SCHEDULE'
  )
    return store.getters['workSchedule/workingNow'];

  return store.getters['workSchedule/comingLater'];
});

function getMaxShiftsShown(gridSize, width, height) {
  const key = `${width}x${height}`;
  return maxShiftsShown[gridSize]?.[key] ?? 0;
}

const showCancelledShifts = computed(() => {
  const settings = store.getters['institution/settings'];
  const departmentId = store.getters['department/id'];

  return settings.shift?.find(
    (item) => parseInt(item.departmentId) === parseInt(departmentId)
  )?.showCancelledFp;
});

const shifts = computed(() => {
  const width = module.value.frWidth;
  const height = module.value.frHeight;

  max.value = getMaxShiftsShown(screenConfig.value.gridSize, width, height);
  let maxShifts = allShifts.value.slice(0, max.value);

  if (!showCancelledShifts.value) {
    maxShifts = maxShifts.filter((shift) => !shift.cancelled);
  }

  return maxShifts;
});

const title = computed(() => {
  if (props.moduleName === 'LATER_WORK_SCHEDULE')
    return i18n.global.t('scheduleShift.workingLater');
  return i18n.global.t('scheduleShift.workingNow');
});

const currentRoute = computed(() => {
  const modules = screenConfig.value.modules.map((module) => module.type);
  const isNormalWS = modules.includes('WORK_SCHEDULE');
  return isNormalWS ? 'workSchedule' : 'simpleWorkSchedule';
});

function goToWorkSchedule() {
  router.push(`/${currentRoute.value}`);
}
</script>

<template>
  <BaseContentCard
    v-if="shifts.length"
    class="bg-macaron-100"
    :routeName="currentRoute"
    :widgetData="shifts"
    :backgroundColor="colorHex"
    :emptyKey="emptyKey"
    :shouldShowDate="false"
    :title="title"
    :useBackgroundImage="false"
    :footerHeight="80"
  >
    <template v-slot:main>
      <EmployeeGrid
        :shifts="shifts"
        :module="module"
        :gridSize="screenConfig.gridSize"
        :isNormalWs="currentRoute === 'workSchedule'"
        @click="goToWorkSchedule"
      ></EmployeeGrid>
    </template>

    <template v-slot:footer>
      <div class="flex items-center justify-between">
        <BaseContent :title="title" :shouldShowMediaCount="false" />
      </div>
    </template>
  </BaseContentCard>

  <WidgetEmptyCard
    v-else
    :emptyKey="emptyKey"
    :backgroundColor="colorHex"
    :icon="icon"
    iconType="fas"
  />
</template>
