<script setup>
defineProps({
  selectedProfileType: {
    type: String,
    default: 'RESIDENT',
  },
});

const emit = defineEmits(['tab-selected']);

const activeTabClasses =
  'border-b-2 text-bold border-about-light-default text-about-light-default font-bold';
</script>

<template>
  <div
    class="flex text-center text-3xl border-b border-gray-200 mt-10 sticky top-0 bg-white font-light"
  >
    <div
      class="flex-auto py-10"
      :class="{ [activeTabClasses]: selectedProfileType === 'RESIDENT' }"
      @click="emit('tab-selected', 'RESIDENT')"
    >
      {{ $t('ourHome.residents') }}
    </div>
    <div
      class="flex-auto py-10"
      :class="{ [activeTabClasses]: selectedProfileType === 'EMPLOYEE' }"
      @click="emit('tab-selected', 'EMPLOYEE')"
    >
      {{ $t('ourHome.employees') }}
    </div>
  </div>
</template>
